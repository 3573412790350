.Intro {
    background: #021420;
    display: flex;
    overflow: hidden;
    padding-top: 150px;
    position: relative;
}

.Intro img {
    position: absolute;
    width: 100%;
}

.Text {
    color: white;
    line-height: 1.75;
    margin: auto;
    padding: 1rem 2rem;
    text-align: center;
    z-index: 5;
}

.Text h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.Link {
    color: #f4b41a;
}

.Link:hover {
    color: #ffcc55;
    text-decoration: none;
}

.Filter {
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 3;
}

@media (min-width: 730px) {
    .Intro {
        padding-top: 85px;
    }
    
    .Text {
        padding: 3rem 1rem;
    }
    
    .Text h1 {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
    }   
}

@media (min-width: 750px) {
    .Intro img {
        height: initial;
        position: initial;
        width: 40%;
    } 

    .Filter {
        display: none;
    }
}

@media (min-width: 1150px) {
    .Intro img {
        width: 30%;
    }

    .Text {
        font-size: 1.25rem;
        line-height: 2;
        width: 50%;
    }
}