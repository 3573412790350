.FooterContacts {
    text-align: center;
}

.FooterContacts h4 {
    margin-bottom: 1rem;
}

.FooterContacts a {
    color: #ecf4f3;
    text-decoration: none;
}

.FooterContacts a:hover {
    color: #d4d4d4;
}

.FooterContacts i {
    margin-right: 0.5rem;
}

.SocialLinks {
    display: flex;
    justify-content: center;
}

.SocialLink i {
    font-size: 1.2rem;
    margin-right: 1rem;
}

@media (min-width: 700px) {
    .FooterContacts {
        text-align: left;
        width: 82%;
    }

    .ContactUs {
        display: flex;
        justify-content: space-between;
    }

    .SocialLinks {
        justify-content: flex-start;
    }
}

@media (min-width: 775px) {
    .FooterContacts {
        width: 75%;
    }
}

@media (min-width: 1025px) {
    .FooterContacts {
        width: 66%;
    }
}