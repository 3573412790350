.FooterBottom {
    margin: 0 auto;
    padding: 0.5rem;
    text-align: center;
}

.FooterBottom a {
    color: #f4b41a;
}

.FooterBottom a:hover {
    color: #ffcc55;
    text-decoration: none;
}