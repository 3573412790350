.CTAButton {
  background: #143d59;
  border: none;
  border-radius: 8px;
  color: #ecf4f3;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  padding: 0;
  transition: all 0.1s ease-out;
}

.CTAButton:hover {
  background: #255d83;
  color: #ecf4f3;
}

.CTAButton:disabled {
  background: #ccc;
  cursor: not-allowed;
  opacity: 50%;
}

.CTAButton a {
  color: inherit;
  display: block;
  height: 100%;
  padding: 1rem;
  text-decoration: none;
  width: 100%;
}

.Link {
  color: inherit;
}
