.FooterLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin-bottom: 2rem;
    padding: 0;
}

@media (min-width: 700px) {
    .FooterLinks {
        flex-direction: column;
        margin-bottom: 0;
    }
}