.Links {
    list-style: none;
    margin: 0;
    padding: 0;
}

@media (min-width: 860px) {
    .Links {
        display: flex;
    }
}