.ContactUs {
    color: #ecf4f3;
    margin: auto;
    max-width: 800px;
    padding: 1rem 0;
}

.ContactUs h4 {
    margin-bottom: 1rem;
}

.ContactUs img {
    animation: appear 1s ease-out;
    margin-top: 2rem;
    max-width: 250px;
    width: 100%;
}

.Captcha {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 1rem auto;
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}