.Header {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20;
}

.Header nav {
    display: none;
}

.MainHeader {
    align-items: center;
    background: white;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.5);
    display: flex;
    font-size: 1.2rem;
    justify-content: space-between;
    padding: 0.5rem 0;
}

.Logo img {
    margin-left: 1rem;
    max-width: 250px;
    width: 80%;
}

.LongLogo  {
    display: none;
}

@media (min-width: 860px) {
    .Header nav {
        display: flex;
    }

    /* .Logo {
        display: none;
    }

    .LongLogo {
        display: block;
        margin-left: 1rem;
        max-width: 500px;
        width: 40%;
    } */
}