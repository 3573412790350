.Jumbotron {
    font-family: 'Poppins', sans-serif;
    height: 90vh;
    position: relative;
    width: 100%;
}

.Image1,
.Image2,
.Image3,
.Image4 {
    background: linear-gradient(to top,rgba(0, 0, 0, 0.5) 55%, transparent), 
    url("https://firebasestorage.googleapis.com/v0/b/c-thru-inspections.appspot.com/o/pexels-pixabay-259588.jpg?alt=media&token=c96c34e4-8647-4511-a2e6-2036c5896476") 40%/cover no-repeat border-box;
    height: 90vh;
    width: 100%
}

.Image2 {
    background: linear-gradient(to top,rgba(0, 0, 0, 0.5) 55%, transparent), 
    url("https://firebasestorage.googleapis.com/v0/b/c-thru-inspections.appspot.com/o/new-home-1530833_1920.jpg?alt=media&token=e7084ee2-b7ff-4064-ba41-9b29449dc71c") 3%/cover no-repeat border-box;
}

.Image3 {
    background: linear-gradient(to top,rgba(0, 0, 0, 0.5) 55%, transparent), 
    url("https://firebasestorage.googleapis.com/v0/b/c-thru-inspections.appspot.com/o/palm-trees-4392365_1920.jpg?alt=media&token=5aa567e0-0b9b-4dde-8db3-bb52d3002fd5") 45%/cover no-repeat border-box;
}

.Image4 {
    background: linear-gradient(to top,rgba(0, 0, 0, 0.5) 55%, transparent), 
    url("https://firebasestorage.googleapis.com/v0/b/c-thru-inspections.appspot.com/o/architecture-1836070_1920.jpg?alt=media&token=e77647b6-2e12-4d81-b4d4-ae23531ba914") 42%/cover no-repeat border-box;
}

.Text {
    color: #ecf4f3;
    left: 0;
    margin: auto;
    padding: 2rem 1rem;
    position: absolute;
    right: 0;
    text-align: center;
    top: 35%;
    width: 90%;
}

.Text h1 {
    font-size: 2rem;
}

.Bar {
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
}

@media (min-width: 700px) {
    .Text {
        top: 40%;
        width: 80%;
    }


    .Text h1 {
        font-size: 3rem;
    }

    .Text p {
        font-size: 1.5rem;
    }
}

@media (min-width: 1000px) {
    .Text {
        width: 65%;
    }
}