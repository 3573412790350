.Scheduler {
    margin: auto;
    max-width: 800px;
    padding: 1rem 0;
}

.Scheduler h4 {
    color: #ecf4f3;
    margin-bottom: 2rem;
}

.Scheduler iframe {
    border: 0;
    height: 500px;
    width: 100%;
}
