.About {
    background: url("https://firebasestorage.googleapis.com/v0/b/c-thru-inspections.appspot.com/o/concrete_seamless.png?alt=media&token=595d974d-62eb-49b2-a113-1413cb1a5826");
    padding: 5rem 1rem;
}

.About img {
    margin: 2rem 0;
    width: 90%;
}

.ContentContainer {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin: auto;
    max-width: 1100px;
}

.Text {
    line-height: 1.75;
    text-align: center;
    width: 90%;
}

.Text h2 {
    color: #143d59;
    margin-bottom: 2rem;
}

.Text p {
    font-size: 1.2rem;
}

.Link {
    color: #f4b41a;
}

.Link:hover {
    color: #ffcc55;
    text-decoration: none;
}

@media (min-width: 700px) {    
    .About {
        padding: 5rem 3rem;
    }

    .About img {
        width: 40%;
    }

    .ContentContainer {
        flex-direction: row;
    }

    .Text {
        text-align: right;
        width: 50%;
    }
}