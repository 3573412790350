.FooterHeader {
    font-size: 1.2rem;
    margin: auto;
    padding-top: 1rem;
    text-align: center;
}

.Logo {
    align-items: center;
    display: flex;
    height: 10rem;
    justify-content: center;
    margin: 0 auto;
    max-width: 250px;
    width: 15rem;
}

.Logo img {
    width: 100%;
}