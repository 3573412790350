.LanguageButton {
  background: #ecf4f3;
  border: none;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.7);
  color: #143d59;
  font-size: 0.75rem;
  font-weight: bold;
  left: 0;
  margin: 0 0.25rem 0.25rem;
  padding: 0.25rem;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.2s ease-out;
  width: 4rem;
  z-index: 25;
}

.LanguageButton:hover {
  background: #e1ece9;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.7);
}

@media (min-width: 700px) {
  .LanguageButton {
    font-size: 1.05rem;
    margin: 0 0.5rem 0.25rem;
    padding: 0.25rem 0.5rem;
    width: 8rem;
  }
}
