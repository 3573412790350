.Bundle {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 35%, transparent),
    url("https://firebasestorage.googleapis.com/v0/b/c-thru-inspections.appspot.com/o/condominium-690086_1920.jpg?alt=media&token=29ff3c4d-0310-44e5-ba48-192ef0e25cb8")
      40% / cover no-repeat border-box;
  background-attachment: fixed;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.5);
  margin: auto;
  padding: 5rem 1rem;
  text-align: center;
}

.Text {
  background: white;
  border-radius: 16px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.5);
  margin: auto;
  padding: 2rem;
}

.Text h1 {
  color: #143d59;
  font-weight: bold;
  /* margin-bottom: 2rem; */
}

.Description {
  font-size: 1.5rem;
  /* margin: 2rem auto 0; */
  margin: 1rem auto 2rem;
}

.Disclaimer {
  font-size: 0.85rem;
  font-style: italic;
  margin: 1.5rem auto 0;
}

@media (min-width: 700px) {
  .Text {
    width: 60%;
  }

  .Text p {
    width: 70%;
  }
}
