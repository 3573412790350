.Service {
    align-items: center;
    background: #ecf4f3;
    border-radius: 16px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0.5rem 2rem;
    max-width: 350px;
    padding: 1.5rem;
    text-align: center;
}

.Service h2 {
    color: #143d59;
}

.Service i {
    background: #143d59;
    border-radius: 50%;
    color: white;
    font-size: 2rem;
    margin: 1rem; 
    padding: 1rem;
}

.Service h3 {
    font-weight: bold;
    margin-bottom: 1rem;
}

.StartingAt {
    font-size: 1rem;
    margin-bottom: 0.25rem;
}

.Disclaimer {
    font-size: 0.75rem;
    font-style: italic;
    margin: 1.5rem auto 0;
}

@media (min-width: 900px) {
    .Service {
        width: 30%;
    }
}