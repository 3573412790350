.Input {
  box-sizing: border-box;
  padding: 10px 0;
  width: 100%;
}

.Label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.InputElement {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  font: inherit;
  outline: none;
  padding: 6px 10px;
  width: 100%;
}

.InputElement:focus {
  background-color: #ccc;
  outline: none;
}

.Invalid {
  background-color: #fda49a;
  border: 1px solid red;
}
