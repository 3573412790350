.Reports {
    background: url("https://firebasestorage.googleapis.com/v0/b/c-thru-inspections.appspot.com/o/concrete_seamless.png?alt=media&token=595d974d-62eb-49b2-a113-1413cb1a5826");
    padding: 5rem 1rem;
}

.Reports img {
    margin: 2rem 0;
    width: 90%;
}

.Reports a {
    background: #143d59;
    border: none;
    border-radius: 8px;
    color: #ecf4f3;
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    margin-top: 2rem;
    padding: 1rem;
    text-decoration: none;
    transition: all 0.1s ease-out;
}

.Reports a:hover {
    background: #255d83;
    color: #ecf4f3;
}

.ContentContainer {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin: auto;
    max-width: 1100px;
}

.Text {
    line-height: 1.75;
    text-align: center;
    width: 90%;
}

.Text h2 {
    color: #143d59;
    margin-bottom: 2rem;
}

.Text p {
    font-size: 1.2rem;
    margin: 0;
}

@media (min-width: 700px) {  
    .Reports {
        padding: 5rem 3rem;
    }
    
    .Reports img {
        width: 40%;
    }

    .ContentContainer {
        flex-direction: row;
    }

    .Text {
        text-align: left;
        width: 50%;
    }
}