.Footer {
    background: #143d59;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.4);
    color: #ecf4f3;
}

.FooterContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2rem auto;
    max-width: 1000px;
    padding: 0 1rem;
}

@media (min-width: 700px) {
    .FooterContent {
        flex-direction: row;
    }
}