.CTASection {
  color: #ecf4f3;
  margin: auto;
  padding: 5rem 0;
  text-align: center;
  width: 90%;
}

.CTASection ul {
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  justify-content: space-between;
  list-style: none;
  margin: 0 auto 2rem;
  max-width: 800px;
  padding: 0;
  position: relative;
}

.CTASection li {
  border-radius: 4px;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  position: absolute;
  transition: all 0.2s ease-out;
}

@media (max-width: 400px) {
  .CTASection ul {
    font-size: 1rem;
  }
}

@media (min-width: 700px) {
  .CTASection {
    padding: 5rem 3rem;
    width: 70%;
  }

  .CTASection ul {
    font-size: 1.5rem;
    justify-content: space-between;
    margin-bottom: 3rem;
  }
}
