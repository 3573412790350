.SideDrawer {
    background: white;
    font-weight: bold;
    height: 100%;
    left: 0;
    max-width: 400px;
    position: fixed;
    text-align: center;
    top: 0;
    transition: transform 0.3s ease-out;
    width: 80%;
    z-index: 100;
}

.SideDrawer img {
    margin: 3rem auto;
    width: 50%;
}

.SideDrawer li {
    font-size: 1.5rem;
    margin: 2rem 0;
}