.Services {
    background: linear-gradient(to top,rgba(0, 0, 0, 0.6) 100%, transparent), 
    url("https://firebasestorage.googleapis.com/v0/b/c-thru-inspections.appspot.com/o/multi-family-1693707_1920.jpg?alt=media&token=337f7d3a-a36b-4657-a107-5819170b6991") 40%/cover no-repeat border-box;
    background-attachment: fixed;
    box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.5);
    margin: auto;
    padding: 5rem 1rem;
    position: relative;
    text-align: center;
}

.Services h1 {
    color: white;
    margin-bottom: 3rem;
}

.ServicesGroup {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    max-width: 1300px;
}

.Logos {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 4rem auto 0;
    max-width: 1300px;
    width: 100%;
}

.Logos img {
    max-width: 275px;
    width: 30%;
}

.ServicesGroup div {
    align-self: stretch;
}

@media (min-width: 700px) {
    .Services {
        padding: 5rem 3rem;
    }

    .ServicesGroup {
        flex-direction: row;
    }

    .Logos img {
        width: 20%;
    }
}